import React from "react";
import { Modal, Checkbox, Button, Row } from "antd";
import API_MANAGER from "../../Api";
import { useRouter } from "next/router";

function ReportingSettingsModal({
  visible,
  setVisible,
  tabs,
  selectedTabs,
  setSelectedTabs,
  getTabs,
  userUuid,
}) {
  const router = useRouter();
  const saveSettings = async () => {
    try {
      const filteredTabs = tabs.filter((tab) => selectedTabs.includes(tab.key));

      const updatedTabs = filteredTabs.map((tab) => ({
        DashboardId: tab.key,
        Name: tab.label,
      }));

      const payload = {
        dashboards: updatedTabs,
      };
      const res = await API_MANAGER.getTabsPatch(userUuid, payload);
      getTabs();
      if (
        updatedTabs &&
        !updatedTabs.some(
          (tab) => tab.DashboardId === router?.query?.dashboardId
        )
      ) {
        router.push(`/`);
      }
      //   router.reload();
    } catch (err) {
      console.log(err, "err");
    }
    setVisible(false);
  };
  const handleTabSelection = (checkedValues) => {
    setSelectedTabs(checkedValues);
  };

  return (
    <>
      {" "}
      <Modal
        title="Select Tabs to Show"
        visible={visible}
        onOk={saveSettings}
        onCancel={() => setVisible(false)}
        className="quicksight-tab-modal"
        footer={false}
      >
        <Checkbox.Group
          options={tabs?.map((tab) => ({
            label: tab.label,
            value: tab.key,
          }))}
          value={selectedTabs}
          onChange={handleTabSelection}
        />

        <Row className="button-row">
          <Button
            className="edly-blue-border-button"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button className="edly-blue-button" onClick={saveSettings}>
            Okay
          </Button>
        </Row>
      </Modal>
    </>
  );
}

export default ReportingSettingsModal;
