import HELPERS from "../utils/helpers";

const API_MANAGER = {
  refreshToken: (data) => {
    return fetch(`${process.env.NEXT_PUBLIC_AUTH_V2}users/refresh-token/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
  },
  login: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      // baseURL: "https://auth-api.dev.edly.co/api/v1",
      url: "users/login",
      method: "POST",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  socialLogin: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: "users/social-login",
      method: "POST",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  resendOtp: (values) => {
    const formData = HELPERS.convertToFormData(values);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/resend-otp`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  adminVerifyOtp: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/verify-otp`,
      method: "POST",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch((err) => {
      // console.log("catch error :", err.response, err.response.status)
      throw err;
    });
  },

  adminForgotPassword: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/forgot-password/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch((err) => {
      // console.log("catch error :", err.response, err.response.status);
      // message.error("Something Went Wrong");
      throw err;
    });
  },

  getAdminStudentOverview: (
    date_range,
    funding_semester,
    funding_semester_range,
    utm_sources,
    ibr_initiated_by,
    ibr_status,
    loan_type,
    school_type,
    school,
    borrower_type
  ) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview?is_active=True${
        date_range ? "&date_range=" + date_range : ""
      }${funding_semester ? "&funding_semester=" + funding_semester : ""}${
        funding_semester_range
          ? "&funding_semester_range=" + funding_semester_range
          : ""
      }${utm_sources ? "&utm_source=" + utm_sources : ""}
      ${ibr_initiated_by ? "&ibr_initiated_by=" + ibr_initiated_by : ""}
      ${ibr_status ? "&ibr_status=" + ibr_status : ""}
      ${loan_type ? "&loan_type=" + loan_type : ""}
      ${school_type ? "&school_type=" + school_type : ""}
      ${school ? "&school=" + school : ""}
      ${borrower_type ? "&borrower_type=" + borrower_type : ""}`,
      method: "GET",
    });
  },

  getAdminStudentOverview1: (data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview?is_active=True${
        data?.date_range ? "&date_range=" + data?.date_range : ""
      }${data?.school_type ? "&school_type=" + data?.school_type : ""}${
        data?.school ? "&school=" + data?.school : ""
      }${data?.loan_type ? "&loan_type=" + data?.loan_type : ""}${
        data?.borrower_type ? "&borrower_type=" + data?.borrower_type : ""
      }${data?.initiated_by ? "&ibr_initiated_by=" + data?.initiated_by : ""}${
        data?.utm_source ? "&utm_source=" + data?.utm_source : ""
      }`,
      method: "GET",
    });
  },

  getStudentExportOverview: (
    date_range,
    funding_semester,
    funding_semester_range,
    utm_sources,
    initiated_by,
    ibr_status,
    loan_type,
    school_type,
    school,
    borrower_type
  ) => {
    // const formData = HELPERS.convertToFormData(data)
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview/csv?is_active=True${
        date_range ? "&date_range=" + date_range : ""
      }${funding_semester ? "&funding_semester=" + funding_semester : ""}${
        funding_semester_range
          ? "&funding_semester_range=" + funding_semester_range
          : ""
      }${utm_sources ? "&utm_source=" + utm_sources : ""}
      ${initiated_by ? "&ibr_initiated_by=" + initiated_by : ""}
      ${ibr_status ? "&ibr_status=" + ibr_status : ""}
      ${loan_type ? "&loan_type=" + loan_type : ""}
      ${school_type ? "&school_type=" + school_type : ""}
      ${school ? "&school=" + school : ""}
      ${borrower_type ? "&borrower_type=" + borrower_type : ""}`,
      // data: formData,
      method: "POST",
    });
  },

  getStudentExportOverview1: (
    date_range,
    utm_sources,
    initiated_by,
    loan_type,
    school_type,
    borrower_type
  ) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview/csv?is_active=True${
        date_range ? "&date_range=" + date_range : ""
      }${utm_sources ? "&utm_source=" + utm_sources : ""}
      ${initiated_by ? "&ibr_initiated_by=" + initiated_by : ""}
      ${loan_type ? "&loan_type=" + loan_type : ""}
      ${school_type ? "&school_type=" + school_type : ""}
      ${borrower_type ? "&borrower_type=" + borrower_type : ""}`,
      // data: formData,
      method: "POST",
    });
  },

  getIbrListExportOverview: (
    date_range,
    funding_semester,
    funding_semester_range,
    utm_sources,
    stage_status,
    data
  ) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview/csv?is_active=True${
        date_range ? "&date_range=" + date_range : ""
      }${funding_semester ? "&funding_semester=" + funding_semester : ""}${
        funding_semester_range
          ? "&funding_semester_range=" + funding_semester_range
          : ""
      }${utm_sources ? "&utm_source=" + utm_sources : ""}${
        stage_status ? "&stage_status" + stage_status : ""
      }`,
      data: formData,
      method: "POST",
    });
  },
  getIbrListExportOverview1: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/overview/csv?is_active=True`,
      data: formData,
      method: "POST",
    });
  },

  fundingInvestorList: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/funding-investor-list/`,
      method: "GET",
    });
  },
  getUniversities: (params) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `${
        params ? `school/?${params && "school_type=" + params}` : `school/`
      }`,
      method: "GET",
    });
  },

  studentListNew: (
    pageNo,
    limit,
    stageStatus,
    userStr,
    search,
    isActiveIbr,
    rangeFilter
  ) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/?limit=${10}&${pageNo && "page=" + pageNo}${
        stageStatus && "&stage_status=" + stageStatus
      }${
        rangeFilter?.funding_semester_range
          ? "&funding_semester_range=" + rangeFilter?.funding_semester_range
          : ""
      }${userStr ? "&users=" + userStr : ""}${
        rangeFilter?.date_range ? "&date_range=" + rangeFilter?.date_range : ""
      }${
        rangeFilter?.funding_semester
          ? "&funding_semester=" + rangeFilter?.funding_semester
          : ""
      }${search && "&search=" + search}${
        isActiveIbr && "&is_active=" + isActiveIbr
      }`,
      method: "GET",
    });
  },

  UserListNew: (Usersstr, search = "", pageNo = "") => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/?user_type=${10}&${Usersstr && "users=" + Usersstr}${
        search && "&search=" + search
      }${pageNo && "&page=" + pageNo}`,
      method: "GET",
    });
  },
  sendBulkInvestor: (data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/bulk-funding-investor/`,
      method: "POST",
      data: data,
    });
  },
  getBulkInvestor: (atype, stype, dtype) => {
    console.log(atype, dtype);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/pending/?${atype ? "ibr__type__slug=" + atype : ""}${
        stype ? "&school_type=" + stype : ""
      }${dtype ? "&ibr__degree_type__slug=" + dtype : ""}`,
      method: "GET",
    });
  },

  getSchoolList: (pageNo, search, term, active, type) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school-list/?limit=10&page=${pageNo}${
        search ? "&search=" + search : ""
      }${active != "" ? "&is_active=" + active : ""}${
        term != "" ? "&has_terms=" + term : ""
      }${type && type != "" ? "&school_type=" + type : ""}`,
      method: "GET",
    });
  },
  getAllSchools: (typeFilter, isActiveFilter, termsFilter) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/schools/overview/csv?${
        typeFilter.length != 0 ? "school_type=" + typeFilter : ""
      }${isActiveFilter.length != 0 ? "&is_active=" + isActiveFilter : ""}${
        termsFilter.length != 0 ? "&has_terms=" + termsFilter : ""
      }`,
      method: "GET",
    });
  },
  addSchool: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school-list/`,
      method: "POST",
      data: formData,
      headers: {},
    });
  },
  getMajorsList: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/major-list/`,
      method: "GET",
    });
  },
  getSchool: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school-list/${id}/`,
      method: "GET",
    });
  },
  getAvailableProgramList: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school/${id}/available-programs/`,
      method: "GET",
    });
  },
  updateAvailableProgramList: (id, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school/${id}/available-programs/`,
      method: "PATCH",
      data: formData,
    });
  },
  getExportAvailableProgram: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/schools/degree-list/csv/${id}/`,
      method: "GET",
    });
  },
  getBankInfo: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school/${id}/school-bank-info`,
      method: "GET",
    });
  },
  getSchoolTermsCSV: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `/students/${id}/export-loan-terms/`,
      method: "GET",
    });
  },
  postBankInfo: (id, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school/${id}/school-bank-info`,
      method: "POST",
      data: formData,
    });
  },
  patchBankInfo: (id, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school/${id}/school-bank-info`,
      method: "PATCH",
      data: formData,
    });
  },
  getSchoolDataList: (schoolName, pageNo, filter) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/loan-terms/?name=${schoolName}&limit=10&${
        pageNo && "page=" + pageNo
      }${filter.major != "" ? "&major=" + filter?.major : ""}${
        filter.degree_type != "" ? "&degree_type=" + filter?.degree_type : ""
      }${filter.months != "" ? "&months_to_employment=" + filter?.months : ""}${
        filter.fund != "" ? "&base_funding_amount=" + filter?.fund : ""
      }${
        filter.application_type != ""
          ? "&application_type=" + filter.application_type
          : ""
      }`,
      method: "GET",
    });
  },

  updateSchool: (data, uuid) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/school-list/${uuid}/`,
      method: "PATCH",
      data: formData,
      headers: {},
    });
  },

  getSchoolStaffList: (pageNo, value) => {
    const newSearchValue = value ? value.replace("+", "%2B") : "";
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `school-staff/?limit=${10}&${pageNo && "page=" + pageNo}&${
        newSearchValue && "search=" + newSearchValue
      }`,
      method: "GET",
    });
  },
  getSchoolStaffListById: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `school-staff/${uuid}/`,
      method: "GET",
    });
  },
  updateSchoolStaff: (data) => {
    const updatedData = JSON.stringify(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/school-staff-update/`,
      method: "PATCH",
      data: updatedData,
      headers: { "Content-Type": "application/json" },
    });
  },
  postStudentSignup: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `/users/signup`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  codingSchoolAction: (action) => {
    const formData = HELPERS.convertToFormData(action);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/school-staff-update/`,
      method: "PATCH",
      data: formData,
    });
  },
  getApplicationList: (pageNo, search, data, pageSize) => {
    const newSearchValue = search.replace("+", "%2B");
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/applications/?limit=${pageSize}&page=${pageNo}${
        newSearchValue ? "&search=" + newSearchValue : ""
      }`,
      data: formData,
      method: "POST",
    });
  },
  adminStudentDetail: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/personal-ibr-details/`,
      method: "GET",
    });
  },
  adminLinkedAppDetails: (ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/ibr/${ibrID}/linked-application-details/`,
      method: "GET",
    });
  },
  getIbrDocumentList: (studentUUID, ibrID, title) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/document/${
        title ? "?is_active=False" : ""
      }${title ? "&title=" + title : ""}
      `,
      method: "GET",
    });
  },

  uploadCustomDocument: (studentUUID, ibrID, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/upload-custom-document/`,
      data: formData,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getBankLinkDetails: (ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/account-details/${ibrID}/`,
      method: "GET",
    });
  },
  resetBankLinkDetails: (studentUUID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/account-relink/${studentUUID}/`,
      method: "POST",
    });
  },
  getTranscriptDetail: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/ibr/${ibrID}/transcript/transcript_detail`,
      method: "GET",
    });
  },
  calculateDTI: (stuId, ibrId, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${stuId}/ibr/${ibrId}/creditor/dti-calculator/`,
      method: "POST",
      data: data,
    });
  },
  getRefreshedData: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/ibr/${ibrID}/transcript/academic-summary-update/`,
      method: "GET",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  postCodingSchool: (studentUUID, ibrID, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/ibr/${ibrID}/admin-approval-status/disbursement/`,
      method: "PATCH",
      data: formData,
    });
  },
  postcertificationAction: (studentUUID, ibrID, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/ibr/${ibrID}/admin-approval-status/certification/`,
      method: "PATCH",
      data: form_data,
    });
  },
  getFundingInvestors: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/funding-investor/`,
      method: "GET",
    });
  },
  patchFundingInvestor: (ibrID, studentUUID, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/funding-investor/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getEstimation: (StudentId, IbrId, salary, interestRate, maturity, terms) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${StudentId}/ibr/${IbrId}/calculator?maturity=${maturity}&interest_rate=${interestRate}&salary=${salary}&terms=${terms}`,
      method: "GET",
    });
  },
  adminActions: (studentId, IbrId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentId}/ibr/${IbrId}/admin-action-detail`,
      method: "GET",
    });
  },

  getOfferApprovalDetails: (studentID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentID}/ibr/${ibrID}/creditor/credit-information`,
      method: "GET",
    });
  },

  getDisbursementDetail: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${(studentUUID, ibrID)}/disbursements`,
      method: "GET",
    });
  },

  requestToReuoload: (stuId, ibrId, data) => {
    // const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${stuId}/ibr/${ibrId}/request-reupload-document/`,
      method: "POST",
      data: data,
    });
  },
  adminActions: (studentId, ibrId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentId}/ibr/${ibrId}/admin-action-detail`,
      method: "GET",
    });
  },
  editNameByAdmin: (studentID, ibrID, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentID}/ibr/${ibrID}/request-name-change/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: form_data,
    });
  },
  nameChangeRequestApproval: (studentId, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentId}/name-change`,
      method: "POST",
      data: form_data,
    });
  },
  nameChangeRequestDocs: (studentId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentId}/ibr/document/`,
      method: "GET",
    });
  },
  emailChange: (studentID, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentID}/email-change`,
      method: "POST",
      data: formData,
    });
  },

  updateStudentIdentity: (id, ibrId, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${id}/ibr/${ibrId}/admin-approval-status/id/`,
      method: "PATCH",
      data: form_data,
    });
  },

  getTranscriptDetails: (StuId, IsaId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${StuId}/ibr/${IsaId}/transcript/get_detail`,
      method: "GET",
    });
  },

  ResetTranscript: (sId, ibrId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/ibr/${ibrId}/transcript/reset`,
      method: "GET",
    });
  },

  fraudAlertApproval: (sId, ibrId, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/ibr/${ibrId}/admin-approval-status/fraud/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  transcriptDocumentApproval: (sId, ibrId, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/ibr/${ibrId}/admin-approval-status/transcript/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  incomeDocumentApproval: (sId, ibrId, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/ibr/${ibrId}/admin-approval-status/income/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getServicers: (studentUUID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `servicer/${studentUUID}/`,
      method: "GET",
    });
  },
  getPaymentHistory: (studentUUID, year, pageNumber, transactionType) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `servicer/${studentUUID}/payment-history/?limit=10&year=${year}&page=${pageNumber}&transaction_type=${transactionType}`,
      method: "GET",
    });
  },
  getPaymentHistoryForGraph: (studentUUID, year, transactionType) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `servicer/${studentUUID}/payment-history-graph/?year=${year}&transaction_type=${transactionType}`,
      method: "GET",
    });
  },

  getIDVerifyDetails: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/id-verification-details/${ibrID}`,
      method: "GET",
    });
  },
  adminStudentEmail: (ibriD) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/students/mail-history/${ibriD}`,
      method: "GET",
    });
  },
  resendAdminMail: (mail, ibriD) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/resend-email/${ibriD}?mail_type=${mail}`,
      method: "GET",
    });
  },
  adminEmailDetail: (userUUID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `student/${userUUID}`,
      method: "GET",
    });
  },
  deactiveIBR: (studentID, ibrID, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentID}/ibr/${ibrID}/deactivate-application`,
      method: "PATCH",
      data: formData,
    });
  },
  deactiveUser: (studentID, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentID}/deactivate-user`,
      method: "PATCH",
      data: formData,
    });
  },
  ResetIdentity: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/forgot-password/`,
      method: "PATCH",
      data: form_data,
    });
  },
  getNewApplicationStatus: (sId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/new-application-approval-status/`,
      method: "PATCH",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getStatusReapply: (sId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${sId}/check-ibrs/`,
      method: "GET",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  confirmIbrCancellation: (data, isa_id, student_id) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${student_id}/isa/${isa_id}/confirm-cancellation`,
      method: "PATCH",
      data: formData,
    });
  },
  getUser: (uuid, search) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: search ? `users/?search=${search}` : `users/${uuid}/`,
      method: "GET",
    });
  },
  getFundingInvestorHistory: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/investor-history/`,
      method: "GET",
    });
  },
  getAlerts: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${studentUUID}/ibr/${ibrID}/application-alerts`,
      method: "GET",
    });
  },
  getStagesCount: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/stages/`,
      method: "GET",
    });
  },
  sendOneLogo: (sId, ibr) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${sId}/ibr/${ibr}/application`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getStudentExternalVendor: (studentUUID, ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/${studentUUID}/ibr/${ibrID}/external-vendors/`,
      method: "GET",
    });
  },

  // getUniversities: (params) => {
  //   return HELPERS.request({
  //     baseURL: process.env.NEXT_PUBLIC_STUDENT,
  //     url: `school?school_type=coding-school`,
  //     method: 'GET',
  //     params,
  //   })
  // },

  createLandingUrl: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/custom-school-landing-page/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });
  },

  getAllLandingSchools: (landingUrl, searchValue) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/custom-school-landing-page/${
        landingUrl
          ? `?landing_url=${landingUrl}`
          : searchValue
          ? `?search=${searchValue}`
          : ""
      }`,
      method: landingUrl ? "DELETE" : "GET",
    });
  },

  // school landing url details
  getLandingSchoolDetails: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/check-school-landing-page/?uuid=${uuid}`,
      method: "GET",
    });
  },

  editLandingUrl: (data, landingUrl) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `students/custom-school-landing-page/?landing_url=${landingUrl}`,
      method: "PATCH",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });
  },

  // sendOneLogo: (studentUUID, ibrID) => {
  //   return HELPERS.secureRequest({
  //     baseURL: process.env.NEXT_PUBLIC_STUDENT,
  //     url: `students/${studentUUID}/ibr/${ibrID}/application`,
  //     method: "POST",
  //     headers: { "Content-Type": "multipart/form-data" },
  //   });
  // },
  sqsHealth: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `consumer/`,
      method: "GET",
    });
  },
  sqsHealthPost: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `consumer/`,
      method: "POST",
      data: formData,
    });
  },
  sqsHealthSend: (data) => {
    const formData = HELPERS.converToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `sqs/`,
      method: "POST",
      data: formData,
    });
  },
  getTermsList: (pageNo, search) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/terms?page=${pageNo}${
        search?.dataIndex ? "&" + search.dataIndex + "=" + search.value : ""
      }`,
      method: "GET",
    });
  },

  codingSchoolResendInvite: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/resend-invite/`,
      method: "PATCH",
      data: formData,
    });
  },
  adminRoleList: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role/`,
      method: "GET",
    });
  },
  generalEnquiries: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `list-general-enquiries/`,
      method: "GET",
    });
  },
  listEnquiries: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `list-enquires/`,
      method: "GET",
    });
  },
  adminList: (pageNo, limit, search) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: search
        ? `users/admin/?search=${search}${limit && "&limit=" + limit}${
            pageNo && "&page=" + pageNo
          }`
        : `users/admin/?${limit && "&limit=" + limit}${
            pageNo && "&page=" + pageNo
          }`,
      method: "GET",
    });
  },

  createAdmin: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/admin/ `,
      method: "POST",
      data: form_data,
    });
  },
  retrieveAdminList: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/admin/?${uuid ? "admin_uuid=" + uuid : ""}`,
      method: "GET",
    });
  },
  deleteAdmin: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/admin/?${uuid ? "admin_uuid=" + uuid : ""}`,
      method: "DELETE",
    });
  },
  updateAdminRole: (uuid, data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/admin/?${uuid ? "admin_uuid=" + uuid : ""}`,
      method: "PATCH",
      data: data,
    });
  },
  adminSetPassword: (userhash, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `/users/verify-user/${userhash}/`,
      method: "PATCH",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  adminSetForgotPassword: (userhash, data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.request({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `/users/set-password/${userhash}/`,
      method: "POST",
      data: form_data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getSchoolType: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `school-type/`,
      method: "GET",
    });
  },
  borrowerActionDetail: (StudentId, IbrId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/ibr/${IbrId}/borrower-actions/`,
      method: "GET",
    });
  },
  roleList: (pageNo) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role/?limit=10${pageNo ? "&page=" + pageNo : "&page=" + 0}`,
      method: "GET",
    });
  },
  servicesList: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/services/`,
      method: "GET",
    });
  },
  createRole: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role/`,
      method: "POST",
      data: form_data,
    });
  },
  updateRole: (data, uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role/?${uuid ? "role_uuid=" + uuid : ""}`,
      method: "PATCH",
      data: data,
    });
  },
  retrieveRole: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role/?${uuid ? "role_uuid=" + uuid : ""}`,
      method: "GET",
    });
  },
  deleteRole: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: `users/role?${uuid ? "role_uuid=" + uuid : ""}`,
      method: "DELETE",
    });
  },
  memberList: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH,
      url: "users/members/",
      method: "GET",
    });
  },
  oneLogoDownload: (data) => {
    const form_data = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/extract_report/`,
      method: "POST",
      data: form_data,
    });
  },
  certificationHistory: (ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `bank/final-application/history/${ibrID ? ibrID : ""}`,
      method: "GET",
    });
  },
  disbursementHistory: (ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/disbursement-information/history/${ibrID ? ibrID : ""}`,
      method: "GET",
    });
  },
  applicationHistory: (ibrID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/loan-application/history/${ibrID ? ibrID : ""}`,
      method: "GET",
    });
  },

  importHistoryList: (pageNo) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/terms-import-history-list/?limit=10&page=${pageNo}`,
      method: "GET",
    });
  },

  uploadImportFile: (data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/upload-terms/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });
  },

  missingRecordList: (uuid, type) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/list-pending-objects/${uuid}/sheet/?record_type=${type}`,
      method: "GET",
    });
  },

  updateMissingRecord: (data, uuid) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/update-program-info/${uuid}/`,
      method: "PATCH",
      data: formData,
      headers: {},
    });
  },

  approveMissingRecord: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/create_missing_records/${uuid}/`,
      method: "POST",
      headers: {},
    });
  },

  approveTermsRecords: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/commit-records/${uuid}/`,
      method: "POST",
    });
  },

  declineMissingRecord: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/reject-batch/${uuid}/`,
      method: "PATCH",
    });
  },

  loanTermList: (pageNo, uuid) => {
    console.log(pageNo, "page", uuid);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_IMPORT,
      url: `terms-import/list-loan-term-staging/${uuid}/?limit=10&page=${pageNo}`,
      method: "GET",
    });
  },
  RetrieveReserveRates: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${id}/advance-rates/?limit=10`,
      method: "GET",
    });
  },
  patchRateChanges: (id, data, selectedID) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${id}/advance-rates/${selectedID}/`,
      method: "PATCH",
      data: formData,
    });
  },
  postRateChanges: (id, data) => {
    const formData = HELPERS.convertToFormData(data);
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${id}/advance-rates/`,
      method: "POST",
      data: formData,
    });
  },
  deleteReserveRates: (id, selectedID) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      url: `students/${id}/advance-rates/${selectedID}/`,
      method: "DELETE",
    });
  },
  getStaffListCSV: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `school-staff/export`,
      method: "GET",
    });
  },
  getTabs: (id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/${id}/dashboard-data/`,
      method: "GET",
    });
  },
  getTabsPatch: (id, data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_AUTH_V2,
      url: `users/${id}/dashboard-data/`,
      method: "PATCH",
      data: data,
    });
  },
  getTransactionType: (ibrid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_SQS,
      url: `servicer/${ibrid}/transaction-type/`,
      method: "GET",
    });
  },
  //refund api's
  createRefundBatch: () => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/batch/`,
      method: "POST",
    });
  },
  getRefundBatchList: (params) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      params,
      url: `refunds/batch/`,
      method: "GET",
    });
  },
  getSingleRefundBatch: (batchId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/batch/${batchId}`,
      method: "GET",
    });
  },
  getRefundBatchById: (batchId) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/application-refund/${batchId}`,
      method: "GET",
    });
  },
  addStudentRefundBatch: (data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/application-refund/`,
      data,
      method: "POST",
    });
  },
  refundSent: (data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/refund-transaction/`,
      data,
      method: "POST",
    });
  },
  reviewRefundSent: (data, old_transaction_id) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/refund-transaction/${old_transaction_id}`,
      data,
      method: "PATCH",
    });
  },
  searchStudent: (params) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT_V2,
      params,
      url: `students/funded-applications/?status=DISBURSEMENT&bucket=disbursement-funded`,
      method: "GET",
    });
  },
  deleteStudent: (uuid) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/application-refund/${uuid}`,
      method: "DELETE",
    });
  },
  editStudent: (uuid, data) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/application-refund/${uuid}`,
      data,
      method: "PATCH",
    });
  },
  deleteBatch: (batchNo) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `/refunds/batch/${batchNo}`,
      method: "DELETE",
    });
  },
  calculateRefund: (uuid, params) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/refund-calculator/${uuid}`,
      params,
      method: "GET",
    });
  },
  getExportRefundBatch: (batch_no) => {
    return HELPERS.secureRequest({
      baseURL: process.env.NEXT_PUBLIC_STUDENT,
      url: `refunds/application-refund/${batch_no}/csv/`,
      method: "GET",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  //refund api section end
};

export default API_MANAGER;
