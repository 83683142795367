import { deleteCookie } from 'cookies-next'
import Router from 'next/router'

const logoutHandler = () => {
  deleteCookie('access_token')
  deleteCookie('refresh_token')
  localStorage.removeItem('userAdminInfo')
  sessionStorage.clear()
  localStorage.clear()
}

const handleRequest = async (res) => {
  let responseError = res?.response?.status
  let responseErrorMessage = res?.response?.data?.message

  if (responseError === 403 && responseErrorMessage === "User doesn't have permission to perform this action") {
    if (typeof window !== "undefined") {
      const { message } = await import('antd');
      message.warn(responseErrorMessage);
    }
  }
  else if (responseError === 403) {
    logoutHandler()
    Router.push('/login')
  }
}

export { handleRequest, logoutHandler }
